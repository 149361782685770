import React, { useRef } from "react";
import { useEffect, useState } from "react";

import axios from 'axios'


/* Import Smart Contract Interraction */
import {
  // ROUTER GET
  loadUserETHBalance,
  loadUserTokenBalance,
  loadTokenAllowance,
  loadTotalAmountRaisedOnThePlatform,
  loadUserReferralReward,
  loadRefRewardClaimed,
  loadUserReferralCount,
  loadTotalReferralReward,
  loadTotalReferralRewardClaimed,
  loadReferralPercentage,
  loadTotalVotes,
  loadVotePercentageRequired,
  loadVotingPossible,
  loadUserVoted,
  loadSecurityRefundEnabled,
  loadAmountInvested,
  loadTotalPoolSupply,
  loadLiquidityTokenPercentage,
  loadTotalLiquidityTokenAmount,
  loadLiquidityEthPercentage,
  loadTeamPercentage,
  loadPlatformPercentage,
  loadTotalInvested,
  loadTotalInvestors,
  loadTotalTokenClaimed,
  loadTotalRefundClaimed,
  loadAmountClaimed,
  loadVestingPercentage,
  loadSaleEnabled,
  loadSaleCompleted,
  loadStartDate,
  loadEndDate,
  loadClaimEnabled,
  loadRefundEnabled,
  loadActiveVesting,
  loadTokenAmountUserWillGet,
  loadMaximumBuy,
  loadMinimumBuy,
  loadTeamAddress,
  loadPlatformAddress,
  loadTotalVesting,
  loadTeamFundsWithdrawn,
  loadPlatformFundsWithdrawn,
  // STAKING GET
  loadUserDFAmountStaked,
  loadUserDF_StakeDaysCount,
  loadMinimumStakeDays,
  loadMinDaysToUnstake,
  loadMinDFStake,
  loadTotalDFAmountStaked,
  loadNoUnstakeDays,
  loadUnstakeFee1,
  loadUnstakeFee2,
  // ROUTER SET
  approveTokenSpending,
  buy,
  claim,
  refund,
  buyWithReferral,
  claimReferralReward,
  finaliseSale,
  securityRefundVote,
  securityRefund,
  withdrawTeamFunds,
  withdrawPlatformFunds,
  // STAKING SET
  stakeDFToken,
  unstakeDFToken,
} from "./util/interact.js";

/* Import Web3-React Error Interfaces For The Connectors */
import {
  useWeb3React,
  UnsupportedChainIdError
} from "@web3-react/core";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import {
  URI_AVAILABLE,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from "@web3-react/walletconnect-connector";
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from "@web3-react/frame-connector";


/* Import Connectors */
import {
  injected,
  walletconnect,
  // Other Connectors ...,
} from "./connectors";
import { useEagerConnect, useInactiveListener } from "./hooks";


/* Initialise Connectors */
const connectorsByName = {
  Injected: injected,
  WalletConnect: walletconnect,
  // Others ..,
};



/* Process Error Messages */
function getErrorMessage(error) {

  if (error) {
    if (error instanceof NoEthereumProviderError) {
      return {
        status: "No BSC browser extension detected, install MetaMask on desktop or visit from a DApp browser on mobile.",
      };
    } else if (error instanceof UnsupportedChainIdError) {
      return {
        status: "You're connected to an unsupported network.",
      };
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorWalletConnect ||
      error instanceof UserRejectedRequestErrorFrame
    ) {
      return {
        status: "Please authorize this website to access your account.",
      };
    } else {
      return {
        status: "WalletConnect connection rejected by user.",
      };
    }
  } else {
    // console.error(error);
    return {
      status: "DecentraFund Platform Fairlaunch Page.",
    };
  }
}



const AppEngine = (props) => {

  /* Web3-React Context */
  const context = useWeb3React();
  const {
    connector,
    library,
    chainId,
    account,
    activate,
    deactivate,
    active,
    error
  } = context;

  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState();
  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);



  /* Import Contracts ABI */
  // Router
  const ROUTER_CONTRACT_ABI = require("./contracts/router-contract-abi.json");
  const ROUTER_CONTRACT_ADDRESS = "0xd4F9eFE6E3dC0771E1fa127De0c5b5F5b8C4681e";
  // Native token
  const DF_CONTRACT_ABI = require("./contracts/df-contract-abi.json");
  const DF_CONTRACT_ADDRESS = "0x6a5d132eaa57C033997Ab6591BD44093628A2E6c";
  // Fairlaunch Staking
  const STAKING_CONTRACT_ABI = require("./contracts/staking-contract-abi.json");
  const STAKING_CONTRACT_ADDRESS = "0xA01b66adf00710a430de0621EaDa261c6e4fc1E1";





  /* State Variables */
  const [status, setStatus] = useState("DecentraFund Platform Fairlaunch Page.");

  const [idoContractAddr] = useState(props.idoContractAddr);
  const [idoData, setIdoData] = useState([]);

  //for tracking smart contract update so the whole app refresh to broadcast the update
  //since we are not using socket that can listen to smart contract events
  const [smUpdate, setSmUpdate] = useState("");

  const [spinner, setSpinner] = useState(false);
  const [stakeState, setStakeState] = useState('STAKE');
  const [stakeAmount, setStakeAmount] = useState(0);
  const [investAmount, setInvestAmount] = useState(0);
  const [referrer, setReferrer] = useState("");

  const [userRefLink, setUserRefLink] = useState("");

  const [dfPrice, setDfPrice] = useState(0);

  const [userETHBalance, setUserETHBalance] = useState(0);
  const [userTokenBalance, setUserTokenBalance] = useState(0);
  const [dfAllowance, setDfAllowance] = useState(0);

  // ROUTER GET
  const [totalAmountRaisedOnThePlatform, setTotalAmountRaisedOnThePlatform] = useState(0);
  const [userReferralReward, setUserReferralReward] = useState(0);
  const [refRewardClaimed, setRefRewardClaimed] = useState(false);
  const [userReferralCount, setUserReferralCount] = useState(0);
  const [totalReferralReward, setTotalReferralReward] = useState(0);
  const [totalReferralRewardClaimed, setTotalReferralRewardClaimed] = useState(0);
  const [referralPercentage, setReferralPercentage] = useState(0);
  // const [totalVotes, setTotalVotes] = useState(0);
  // const [votePercentageRequired, setVotePercentageRequired] = useState(0);
  const [votingPossible, setVotingPossible] = useState(false);
  const [userVoted, setUserVoted] = useState(false);
  const [securityRefundEnabled, setSecurityRefundEnabled] = useState(false);
  const [amountInvested, setAmountInvested] = useState(0);
  const [totalPoolSupply, setTotalPoolSupply] = useState(0);
  const [liquidityTokenPercentage, setLiquidityTokenPercentage] = useState(0);
  const [totalLiquidityTokenAmount, setTotalLiquidityTokenAmount] = useState(0);
  const [liquidityEthPercentage, setLiquidityEthPercentage] = useState(0);
  const [teamPercentage, setTeamPercentage] = useState(0);
  const [platformPercentage, setPlatformPercentage] = useState(0);
  const [totalInvested, setTotalInvested] = useState(0);
  const [totalInvestors, setTotalInvestors] = useState(0);
  const [totalTokenClaimed, setTotalTokenClaimed] = useState(0);
  const [totalRefundClaimed, setTotalRefundClaimed] = useState(0);
  const [amountClaimed, setAmountClaimed] = useState(0);
  const [vestingPercentage, setVestingPercentage] = useState(0);
  const [saleEnabled, setSaleEnabled] = useState(false);
  const [saleCompleted, setSaleCompleted] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [claimEnabled, setClaimEnabled] = useState(false);
  const [refundEnabled, setRefundEnabled] = useState(false);
  const [activeVesting, setActiveVesting] = useState(0);
  const [tokenAmountUserWillGet, setTokenAmountUserWillGet] = useState(0);
  const [maximumBuy, setMaximumBuy] = useState(0);
  const [minimumBuy, setMinimumBuy] = useState(0);
  // const [teamAddress, setTeamAddress] = useState('');
  // const [platformAddress, setPlatformAddress] = useState('');
  const [totalVesting, setTotalVesting] = useState(0);
  const [teamFundsWithdrawn, setTeamFundsWithdrawn] = useState(false);
  const [platformFundsWithdrawn, setPlatformFundsWithdrawn] = useState(false);

  // STAKING GET
  const [userDFAmountStaked, setUserDFAmountStaked] = useState(0);
  const [userDF_StakeDaysCount, setUserDF_StakeDaysCount] = useState(0);
  const [minimumStakeDays, setMinimumStakeDays] = useState(0);
  const [minDaysToUnstake, setMinDaysToUnstake] = useState(0);
  const [minDFStake, setMinDFStake] = useState(0);
  const [totalDFAmountStaked, setTotalDFAmountStaked] = useState(0);
  const [noUnstakeDays, setNoUnstakeDays] = useState(0);
  const [unstakeFee1, setUnstakeFee1] = useState(0);
  const [unstakeFee2, setUnstakeFee2] = useState(0);


  const [countdownString, setCountdownString] = useState("Processing...");
  const [countdownDays, setCountdownDays] = useState("");
  const [countdownHours, setCountdownHours] = useState("");
  const [countdownMinutes, setCountdownMinutes] = useState("");
  const [countdownSeconds, setCountdownSeconds] = useState("");




  // load DB Data // called only once
  useEffect(() => {

    // axios.get('http://localhost:8000/app-api/' + idoContractAddr)
    axios.get('https://decentrafund.io/app-api/' + idoContractAddr)
      .then((res) => {
        setIdoData(res.data[0]);
      })
      .catch((err) => {
        console.log("An HTTP error occured");
      })

  }, [idoContractAddr]);


  // // load price Data // called only once
  // useEffect(() => {

  //   axios.get('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=decentrafund')
  //     .then((res) => {
  //       setDfPrice(res.data[0].current_price);
  //     })
  //     .catch((err) => {
  //       console.log("An HTTP error occured");
  //     })

  // }, []);





  //connection dependent calls in conditional useEffect
  useEffect(() => {

    if (library) {

      var ROUTER_CONTRACT = new library.eth.Contract(
        ROUTER_CONTRACT_ABI,
        ROUTER_CONTRACT_ADDRESS
      );

      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );

      async function fetchTotalAmountRaisedOnThePlatform() {
        const result = await loadTotalAmountRaisedOnThePlatform(library, ROUTER_CONTRACT);
        setTotalAmountRaisedOnThePlatform(parseFloat(result));
      }
      fetchTotalAmountRaisedOnThePlatform()

      async function fetchTotalReferralReward() {
        const result = await loadTotalReferralReward(library, ROUTER_CONTRACT, idoContractAddr);
        setTotalReferralReward(parseFloat(result));
      }
      fetchTotalReferralReward()

      async function fetchTotalReferralRewardClaimed() {
        const result = await loadTotalReferralRewardClaimed(library, ROUTER_CONTRACT, idoContractAddr);
        setTotalReferralRewardClaimed(parseFloat(result));
      }
      fetchTotalReferralRewardClaimed()

      async function fetchReferralPercentage() {
        const result = await loadReferralPercentage(ROUTER_CONTRACT, idoContractAddr);
        setReferralPercentage(parseFloat(result));
      }
      fetchReferralPercentage()

      // async function fetchTotalVotes() {
      //   const result = await loadTotalVotes(ROUTER_CONTRACT, idoContractAddr);
      //   setTotalVotes(parseFloat(result));
      // }
      // fetchTotalVotes()

      // async function fetchVotePercentageRequired() {
      //   const result = await loadVotePercentageRequired(ROUTER_CONTRACT, idoContractAddr);
      //   setVotePercentageRequired(parseFloat(result));
      // }
      // fetchVotePercentageRequired()

      async function fetchVotingPossible() {
        const result = await loadVotingPossible(ROUTER_CONTRACT, idoContractAddr);
        setVotingPossible(result);
      }
      fetchVotingPossible()

      async function fetchSecurityRefundEnabled() {
        const result = await loadSecurityRefundEnabled(ROUTER_CONTRACT, idoContractAddr);
        setSecurityRefundEnabled(result);
      }
      fetchSecurityRefundEnabled()

      async function fetchTotalPoolSupply() {
        const result = await loadTotalPoolSupply(library, ROUTER_CONTRACT, idoContractAddr);
        setTotalPoolSupply(parseFloat(result));
      }
      fetchTotalPoolSupply()

      async function fetchLiquidityTokenPercentage() {
        const result = await loadLiquidityTokenPercentage(ROUTER_CONTRACT, idoContractAddr);
        setLiquidityTokenPercentage(result);
      }
      fetchLiquidityTokenPercentage()

      async function fetchTotalLiquidityTokenAmount() {
        const result = await loadTotalLiquidityTokenAmount(library, ROUTER_CONTRACT, idoContractAddr);
        setTotalLiquidityTokenAmount(parseFloat(result));
      }
      fetchTotalLiquidityTokenAmount()

      async function fetchLiquidityEthPercentage() {
        const result = await loadLiquidityEthPercentage(ROUTER_CONTRACT, idoContractAddr);
        setLiquidityEthPercentage(result);
      }
      fetchLiquidityEthPercentage()

      async function fetchTeamPercentage() {
        const result = await loadTeamPercentage(ROUTER_CONTRACT, idoContractAddr);
        setTeamPercentage(result);
      }
      fetchTeamPercentage()

      async function fetchPlatformPercentage() {
        const result = await loadPlatformPercentage(ROUTER_CONTRACT, idoContractAddr);
        setPlatformPercentage(result);
      }
      fetchPlatformPercentage()

      async function fetchTotalInvested() {
        const result = await loadTotalInvested(library, ROUTER_CONTRACT, idoContractAddr);
        setTotalInvested(parseFloat(result));
        // set total invested to cloud if it has changed
        if (idoData.total_invested !== parseFloat(result) && parseFloat(result) !== 0) {
          // axios.get('http://localhost:8000/app-api/' + idoContractAddr + '/' + result);
          // axios.get('https://beta.decentrafund.io/app-api/' + idoContractAddr + '/' + result);
          axios.get('https://decentrafund.io/app-api/' + idoContractAddr + '/' + result);
        }
      }
      fetchTotalInvested()

      async function fetchTotalInvestors() {
        const result = await loadTotalInvestors(library, ROUTER_CONTRACT, idoContractAddr);
        setTotalInvestors(parseFloat(result));
      }
      fetchTotalInvestors()

      async function fetchTotalTokenClaimed() {
        const result = await loadTotalTokenClaimed(library, ROUTER_CONTRACT, idoContractAddr);
        setTotalTokenClaimed(parseFloat(result));
      }
      fetchTotalTokenClaimed()

      async function fetchTotalRefundClaimed() {
        const result = await loadTotalRefundClaimed(library, ROUTER_CONTRACT, idoContractAddr);
        setTotalRefundClaimed(parseFloat(result));
      }
      fetchTotalRefundClaimed()

      async function fetchVestingPercentage() {
        const result = await loadVestingPercentage(library, ROUTER_CONTRACT, idoContractAddr, activeVesting == 0 ? 1 : activeVesting);
        setVestingPercentage(parseFloat(result));
      }
      fetchVestingPercentage()

      async function fetchSaleEnabled() {
        const result = await loadSaleEnabled(ROUTER_CONTRACT, idoContractAddr);
        setSaleEnabled(result);
      }
      fetchSaleEnabled()

      async function fetchSaleCompleted() {
        const result = await loadSaleCompleted(ROUTER_CONTRACT, idoContractAddr);
        setSaleCompleted(result);
      }
      fetchSaleCompleted()

      async function fetchStartDate() {
        const result = await loadStartDate(ROUTER_CONTRACT, idoContractAddr);
        setStartDate(parseFloat(result));
      }
      fetchStartDate()

      async function fetchEndDate() {
        const result = await loadEndDate(ROUTER_CONTRACT, idoContractAddr);
        setEndDate(parseFloat(result));
      }
      fetchEndDate()

      async function fetchClaimEnabled() {
        const result = await loadClaimEnabled(ROUTER_CONTRACT, idoContractAddr);
        setClaimEnabled(result);
      }
      fetchClaimEnabled()

      async function fetchRefundEnabled() {
        const result = await loadRefundEnabled(ROUTER_CONTRACT, idoContractAddr);
        setRefundEnabled(result);
      }
      fetchRefundEnabled()

      async function fetchActiveVesting() {
        const result = await loadActiveVesting(ROUTER_CONTRACT, idoContractAddr);
        setActiveVesting(parseFloat(result));
      }
      fetchActiveVesting()

      async function fetchMaximumBuy() {
        const result = await loadMaximumBuy(library, ROUTER_CONTRACT, idoContractAddr);
        setMaximumBuy(parseFloat(result));
      }
      fetchMaximumBuy()

      async function fetchMinimumBuy() {
        const result = await loadMinimumBuy(library, ROUTER_CONTRACT, idoContractAddr);
        setMinimumBuy(parseFloat(result));
      }
      fetchMinimumBuy()

      // async function fetchTeamAddress() {
      //   const result = await loadTeamAddress(ROUTER_CONTRACT, idoContractAddr);
      //   setTeamAddress(result);
      // }
      // fetchTeamAddress()

      // async function fetchPlatformAddress() {
      //   const result = await loadPlatformAddress(ROUTER_CONTRACT, idoContractAddr);
      //   setPlatformAddress(result);
      // }
      // fetchPlatformAddress()

      async function fetchTotalVesting() {
        const result = await loadTotalVesting(ROUTER_CONTRACT, idoContractAddr);
        setTotalVesting(parseFloat(result));
      }
      fetchTotalVesting()

      async function fetchTeamFundsWithdrawn() {
        const result = await loadTeamFundsWithdrawn(ROUTER_CONTRACT, idoContractAddr);
        setTeamFundsWithdrawn(result);
      }
      fetchTeamFundsWithdrawn()

      async function fetchPlatformFundsWithdrawn() {
        const result = await loadPlatformFundsWithdrawn(ROUTER_CONTRACT, idoContractAddr);
        setPlatformFundsWithdrawn(result);
      }
      fetchPlatformFundsWithdrawn()

      async function fetchMinimumStakeDays() {
        const result = await loadMinimumStakeDays(STAKING_CONTRACT);
        setMinimumStakeDays(parseFloat(result));
      }
      fetchMinimumStakeDays()

      async function fetchMinDaysToUnstake() {
        const result = await loadMinDaysToUnstake(STAKING_CONTRACT);
        setMinDaysToUnstake(parseFloat(result));
      }
      fetchMinDaysToUnstake()

      async function fetchMinDFStake() {
        const result = await loadMinDFStake(library, STAKING_CONTRACT);
        setMinDFStake(parseFloat(result));
      }
      fetchMinDFStake()

      async function fetchTotalDFAmountStaked() {
        const result = await loadTotalDFAmountStaked(library, STAKING_CONTRACT);
        setTotalDFAmountStaked(parseFloat(result));
      }
      fetchTotalDFAmountStaked()

      async function fetchNoUnstakeDays() {
        const result = await loadNoUnstakeDays(STAKING_CONTRACT);
        setNoUnstakeDays(parseFloat(result));
      }
      fetchNoUnstakeDays()

      async function fetchUnstakeFee1() {
        const result = await loadUnstakeFee1(STAKING_CONTRACT);
        setUnstakeFee1(parseFloat(result));
      }
      fetchUnstakeFee1()

      async function fetchUnstakeFee2() {
        const result = await loadUnstakeFee2(STAKING_CONTRACT);
        setUnstakeFee2(parseFloat(result));
      }
      fetchUnstakeFee2()

    }

    if (error) {
      const { status } = getErrorMessage(error);
      setStatus(status);
    }

    getReferrer();

  }, [library, chainId, smUpdate]);


  // wallet dependent calls in conditional useEffect
  useEffect(() => {

    if (library && account) {

      var DF_CONTRACT = new library.eth.Contract(
        DF_CONTRACT_ABI,
        DF_CONTRACT_ADDRESS
      );
      var ROUTER_CONTRACT = new library.eth.Contract(
        ROUTER_CONTRACT_ABI,
        ROUTER_CONTRACT_ADDRESS
      );

      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );


      async function fetchUserETHBalance() {
        const result = await loadUserETHBalance(library, account);
        setUserETHBalance(parseFloat(result));
      }
      fetchUserETHBalance()

      async function fetchUserTokenBalance() {
        const result = await loadUserTokenBalance(library, DF_CONTRACT, account);
        setUserTokenBalance(parseFloat(result));
      }
      fetchUserTokenBalance()

      async function fetchDfAllowance() {
        const result = await loadTokenAllowance(library, DF_CONTRACT, account, STAKING_CONTRACT_ADDRESS);
        setDfAllowance(parseFloat(result));
      }
      fetchDfAllowance()

      async function fetchUserReferralReward() {
        const result = await loadUserReferralReward(library, ROUTER_CONTRACT, idoContractAddr, account);
        setUserReferralReward(parseFloat(result));
      }
      fetchUserReferralReward()

      async function fetchRefRewardClaimed() {
        const result = await loadRefRewardClaimed(ROUTER_CONTRACT, idoContractAddr, account);
        setRefRewardClaimed(result);
      }
      fetchRefRewardClaimed()

      async function fetchUserReferralCount() {
        const result = await loadUserReferralCount(ROUTER_CONTRACT, idoContractAddr, account);
        setUserReferralCount(parseFloat(result));
      }
      fetchUserReferralCount()

      async function fetchUserVoted() {
        const result = await loadUserVoted(ROUTER_CONTRACT, idoContractAddr, account);
        setUserVoted(result);
      }
      fetchUserVoted()

      async function fetchAmountInvested() {
        const result = await loadAmountInvested(library, ROUTER_CONTRACT, idoContractAddr, account);
        setAmountInvested(parseFloat(result));
      }
      fetchAmountInvested()

      async function fetchTokenAmountUserWillGet() {
        const result = await loadTokenAmountUserWillGet(library, ROUTER_CONTRACT, idoContractAddr, account);
        setTokenAmountUserWillGet(parseFloat(result));
      }
      fetchTokenAmountUserWillGet()

      async function fetchAmountClaimed() {
        const result = await loadAmountClaimed(library, ROUTER_CONTRACT, idoContractAddr, account);
        setAmountClaimed(parseFloat(result));
      }
      fetchAmountClaimed()

      async function fetchUserDFAmountStaked() {
        const result = await loadUserDFAmountStaked(library, STAKING_CONTRACT, account);
        setUserDFAmountStaked(parseFloat(result));
      }
      fetchUserDFAmountStaked()

      async function fetchUserDF_StakeDaysCount() {
        const result = await loadUserDF_StakeDaysCount(STAKING_CONTRACT, account);
        setUserDF_StakeDaysCount(parseFloat(result));
      }
      fetchUserDF_StakeDaysCount()

      processRefLink();

      if (error) {
        const { status } = getErrorMessage(error);
        setStatus(status);
      }
    }

  }, [library, account, chainId, smUpdate])//add the dependence in array


  // error dependent calls in conditional useEffect
  useEffect(() => {

    const { status } = getErrorMessage(error);
    setStatus(status);

  }, [error]);


  ///////////////////////////////////////////////////////////////////////////////////////////////////
  // log the walletconnect URI
  React.useEffect(() => {
    const logURI = uri => {
      // console.log("WalletConnect URI", uri);
      console.log("WalletConnect URI Available");
    };
    walletconnect.on(URI_AVAILABLE, logURI);

    return () => {
      walletconnect.off(URI_AVAILABLE, logURI);
    };
  }, []);
  ///////////////////////////////////////////////////////////////////////////////////////////////////




  //process countdown dependent in idoData
  useEffect(() => {
    processCountdown();
  }, [startDate, endDate]);


  function processCountdown() {
    if (startDate) {

      // Update the count down every 1 second
      var x = setInterval(function () {

        // Set the date we're counting down to
        // var countDownDate = new Date(startDate + "  UTC").getTime();
        // var countDownDate = new Date("Jan 5, 2024 15:37:25").getTime();
        var countDownDate = startDate;

        // var now = new Date().getTime();
        // Get today's date and time in seconds
        var now = Math.floor(new Date().getTime() / 1000);

        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        setCountdownString("Starting in");

        if (distance <= 0) {
          // countDownDate = new Date(endDate + "  UTC").getTime();
          countDownDate = endDate;
          // now = new Date().getTime();
          now = Math.floor(new Date().getTime() / 1000);
          distance = countDownDate - now;
          setCountdownString("Ending in");
        }

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (60 * 60 * 24));
        var hours = Math.floor((distance % (60 * 60 * 24)) / (60 * 60));
        var minutes = Math.floor((distance % (60 * 60)) / 60);
        var seconds = Math.floor(distance % 60);

        // Display the result in the element with id="demo"
        setCountdownDays(days);
        setCountdownHours(hours);
        setCountdownMinutes(minutes);
        setCountdownSeconds(seconds);

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);

          setCountdownDays(0);
          setCountdownHours(0);
          setCountdownMinutes(0);
          setCountdownSeconds(0);
        }
      }, 1000);
    } else {
      setCountdownDays(0);
      setCountdownHours(0);
      setCountdownMinutes(0);
      setCountdownSeconds(0);
    }
  }


  //to get referral address from URL
  function getReferrer() {
    if (window.location.href.indexOf("?ref=") > 0) {
      var completeUrl = window.location.href;
      var refStr = completeUrl.substring(completeUrl.indexOf("?ref=") + 5);
      setReferrer(refStr);
    } else {
      setReferrer("");
    }
  }

  // to get user referral link
  function processRefLink() {
    var userRefLinkStr;
    if (referrer.length > 0) {
      var completeUrl = window.location.href;
      userRefLinkStr = completeUrl.substring(0, completeUrl.indexOf("?ref=")) + "?ref=" + account;
      setUserRefLink(userRefLinkStr);
    } else {
      userRefLinkStr = window.location.href + "?ref=" + account;
      setUserRefLink(userRefLinkStr);
    }
  }

  const modalCloseButtonRef = useRef(null);

  const metamaskConnectPressed = async () => {
    setActivatingConnector(connectorsByName["Injected"]);
    activate(connectorsByName["Injected"]);
    modalCloseButtonRef.current.click();
  };

  const walletconnectConnectPressed = async () => {
    setActivatingConnector(connectorsByName["WalletConnect"]);
    activate(connectorsByName["WalletConnect"]);
    modalCloseButtonRef.current.click();
  };

  const disconnectWalletPressed = async () => {
    if (active || error) {
      if (connector !== walletconnect) {//Logging out Metamask
        deactivate();
      } else {//Logging out WalletConnect
        connector.close();
      }
    }
  };


  ///////////////////////////////////////////////////////////////////////////////////////



  // ROUTER SET
  const onBuyTokenPressed = async () => {
    setSpinner(true)
    if (active && library && account) {
      if (!saleEnabled) {
        setStatus("Sale isn't currently active");
      } else {

        var ROUTER_CONTRACT = new library.eth.Contract(
          ROUTER_CONTRACT_ABI,
          ROUTER_CONTRACT_ADDRESS
        );

        const { status, smUpdate } = await buy(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account, investAmount);
        setStatus(status);
        setSmUpdate(smUpdate);
        setInvestAmount(0);
      }
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };
  const onBuyTokenRefPressed = async () => {
    setSpinner(true)
    if (active && library && account) {
      if (!saleEnabled) {
        setStatus("Sale isn't currently active");
      } else {

        var ROUTER_CONTRACT = new library.eth.Contract(
          ROUTER_CONTRACT_ABI,
          ROUTER_CONTRACT_ADDRESS
        );

        if (referrer.length > 0) {
          const { status, smUpdate } = await buyWithReferral(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account, referrer, investAmount);
          setStatus(status);
          setSmUpdate(smUpdate);
          setInvestAmount(0);
        } else {
          const { status, smUpdate } = await buy(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account, investAmount);
          setStatus(status);
          setSmUpdate(smUpdate);
          setInvestAmount(0);
        }
      }
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }
  };

  const onClaimPressed = async () => {
    setSpinner(true)
    if (active && library && account) {
      if (!claimEnabled) {
        setStatus("Claim isn't currently active");
      } else {

        var ROUTER_CONTRACT = new library.eth.Contract(
          ROUTER_CONTRACT_ABI,
          ROUTER_CONTRACT_ADDRESS
        );

        const { status, smUpdate } = await claim(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account);
        setStatus(status);
        setSmUpdate(smUpdate);
      }
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }
  };

  const onClaimRefRewardPressed = async () => {
    setSpinner(true)
    if (active && library && account) {
      if (!claimEnabled) {
        setStatus("Claim isn't currently active");
      } else {
        if (refRewardClaimed === true) {
          setStatus("You have claimed yor referral reward");
        } else {
          if (userReferralReward > 0) {

            var ROUTER_CONTRACT = new library.eth.Contract(
              ROUTER_CONTRACT_ABI,
              ROUTER_CONTRACT_ADDRESS
            );

            const { status, smUpdate } = await claimReferralReward(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account);
            setStatus(status);
            setSmUpdate(smUpdate);
          } else {
            setStatus("You don't have referral reward");
          }
        }
      }
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }
  };

  const onFinaliseSalePressed = async () => {
    setSpinner(true)
    if (active && library && account) {
      if (saleEnabled) {
        setStatus("You can't finalise sale when it is currently active");
      } else if (claimEnabled) {
        setStatus("Sale already Finalised");
      } else if (refundEnabled) {
        setStatus("Sale already Finalised");
      } else if (securityRefundEnabled) {
        setStatus("Sale can't be Finalised when Security Refund is alredy enabled");
      } else {

        var ROUTER_CONTRACT = new library.eth.Contract(
          ROUTER_CONTRACT_ABI,
          ROUTER_CONTRACT_ADDRESS
        );

        const { status, smUpdate } = await finaliseSale(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account);
        setStatus(status);
        setSmUpdate(smUpdate);
      }
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }
  };

  const onRefundPressed = async () => {
    setSpinner(true)
    if (active && library && account) {

      var ROUTER_CONTRACT = new library.eth.Contract(
        ROUTER_CONTRACT_ABI,
        ROUTER_CONTRACT_ADDRESS
      );

      const { status, smUpdate } = await refund(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account);
      setStatus(status);
      setSmUpdate(smUpdate);
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }
  };

  const onVotePressed = async () => {
    setSpinner(true)
    if (active && library && account) {

      var ROUTER_CONTRACT = new library.eth.Contract(
        ROUTER_CONTRACT_ABI,
        ROUTER_CONTRACT_ADDRESS
      );

      const { status, smUpdate } = await securityRefundVote(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account);
      setStatus(status);
      setSmUpdate(smUpdate);
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }
  };

  const onSecurityRefundPressed = async () => {
    setSpinner(true)
    if (active && library && account) {

      var ROUTER_CONTRACT = new library.eth.Contract(
        ROUTER_CONTRACT_ABI,
        ROUTER_CONTRACT_ADDRESS
      );

      const { status, smUpdate } = await securityRefund(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account);
      setStatus(status);
      setSmUpdate(smUpdate);
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }
  };

  const onWithdrawTeamFundsPressed = async () => {
    setSpinner(true)
    if (active && library && account) {

      var ROUTER_CONTRACT = new library.eth.Contract(
        ROUTER_CONTRACT_ABI,
        ROUTER_CONTRACT_ADDRESS
      );

      const { status, smUpdate } = await withdrawTeamFunds(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account);
      setStatus(status);
      setSmUpdate(smUpdate);
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }
  };

  const onWithdrawPlatformFundsPressed = async () => {
    setSpinner(true)
    if (active && library && account) {

      var ROUTER_CONTRACT = new library.eth.Contract(
        ROUTER_CONTRACT_ABI,
        ROUTER_CONTRACT_ADDRESS
      );

      const { status, smUpdate } = await withdrawPlatformFunds(library, ROUTER_CONTRACT_ADDRESS, ROUTER_CONTRACT, idoContractAddr, account);
      setStatus(status);
      setSmUpdate(smUpdate);
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }
  };



  const onApproveTokenPressed = async () => {
    setSpinner(true)
    if (active && library && account) {

      var DF_CONTRACT = new library.eth.Contract(
        DF_CONTRACT_ABI,
        DF_CONTRACT_ADDRESS
      );
      const { status, smUpdate } = await approveTokenSpending(library, DF_CONTRACT, DF_CONTRACT_ADDRESS, STAKING_CONTRACT_ADDRESS, account, stakeAmount);
      setStatus(status);
      setSmUpdate(smUpdate);
      setDfAllowance(parseFloat(stakeAmount));
      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };


  // STAKING SET
  const onStakeTokenPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {

      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );

      const { status, smUpdate } = await stakeDFToken(library, STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT, account, stakeAmount);
      setStatus(status);
      setSmUpdate(smUpdate);
      setStakeAmount(0);

      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };

  const onUnstakeTokenPressed = async () => { //TODO: implement
    setSpinner(true)
    if (active && library && account) {

      var STAKING_CONTRACT = new library.eth.Contract(
        STAKING_CONTRACT_ABI,
        STAKING_CONTRACT_ADDRESS
      );

      const { status, smUpdate } = await unstakeDFToken(library, STAKING_CONTRACT_ADDRESS, STAKING_CONTRACT, account, stakeAmount);
      setStatus(status);
      setSmUpdate(smUpdate);
      setStakeAmount(0);

      setSpinner(false)
    } else {
      setStatus("You must be connected to the Blockchain.");
      setSpinner(false)
    }

  };



  //the UI of our component
  return (

    <>
      <main className="details">
        <div className="container">
          <div className="m-3 p-lg-1" />
          <div className="row g-4">
            <div className="col-lg-6">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://decentrafund.io/">Home</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="https://decentrafund.io/launchpad">Launchpad</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    {idoData.name}
                  </li>
                </ol>
              </nav>
            </div>
            <div className="col-lg-6">

              {(!active) && (
                <button
                  className="btn connect"
                  data-bs-toggle="modal"
                  data-bs-target="#connectModal"
                >
                  Connect wallet
                </button>
              )}

              {(active && account && connector) &&
                <p className="btn btn-outline-warning">

                  <img src={connector !== walletconnect ? "images/wallets/metamask.png" : "images/wallets/walletconnect.png"} alt="" />
                  {account.substring(0, 5) + "..." + account.substring(38)}
                </p>
              }

              {(active) && (
                <button
                  className="btn btn-light mb-3 px-1"
                  style={{ border: 0, background: "transparent", color: "white" }}
                  id="logoutButton"
                  onClick={disconnectWalletPressed}
                >
                  Disconnect
                </button>
              )}

            </div>
          </div>
          <div className="projects hero">
            <div className="row g-4">
              <div className="col-lg-6">
                <div className="project-image">
                  <img
                    src={idoData.banner}
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="project-card">
                  <div className="project-title">{idoData.name}</div>
                  {/* <p class="project-short-desc"> */}
                  <p style={{ fontSize: 12, margin: 0 }}>
                    {idoData.s_detail}
                  </p>
                  <a className="moreinfo-trigger" href="#other-details">
                    More Details
                  </a>
                  <div className="card__content">
                    {/* <div className="project-status">
                      <div className="date">10 Mar 2022</div>
                      <div className="project-progress">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <div className="progress-text">52%</div>
                          </div>
                          <div className="col">
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: "52%" }}
                                aria-valuenow={52}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="project-commercials">
                      <div className="current-bid">
                        <div className="price">
                          <span>
                            {totalInvested} <small className="text-muted">ETH</small>
                          </span>
                        </div>
                        <p className="detail-head">Total ETH Committed</p>
                      </div>
                      <div className="token-price">
                        <div className="price">
                          <span>
                            {totalPoolSupply}{" "}
                            <small className="text-muted">{idoData.ticker}</small>
                          </span>
                        </div>
                        <p className="detail-head">Pool Supply</p>
                      </div>
                      <div className="countdown">
                        <div className="timer">
                          <span>{countdownDays}d {countdownHours}h {countdownMinutes}m {countdownSeconds}s</span>
                        </div>
                        <p className="detail-head">{countdownString}</p>
                      </div>
                    </div>
                    {/* <a href="#" className="btn connect">
                      Connect wallet
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="m-3" />
          <div className="projects project__cards projects__completed">
            <div className="p__body">
              <div className="projects-list">
                <div className="project-card detail-card">
                  <h4 className="mb-5">Stake / Unstake DFB</h4>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col">
                        <div className="price">
                          <span>
                            {userDFAmountStaked} <small>DFB</small>
                          </span>
                        </div>
                        <p className="detail-head">You Staked</p>
                      </div>
                      <div className="col">
                        <div className="price">
                          <span>
                            {userDF_StakeDaysCount} <small>Days</small>
                          </span>
                        </div>
                        <p className="detail-head">Days</p>
                      </div>
                    </div>
                  </div>
                  <div className="m-4" />
                  {/* <label htmlFor="stake-unstake-df" className="form-label">
                    Stake / Unstake DFB
                  </label> */}
                  <select
                    className="form-select form-select-lg mb-3"
                    aria-label=".form-select-lg example"
                    value={stakeState}
                    onChange={(e) => setStakeState(e.target.value)}
                  >
                    <option value="STAKE">Stake</option>
                    <option value="UNSTAKE">Unstake</option>
                  </select>

                  <div className="m-4" />

                  <div className="input-group input-group-lg mb-3 fc-left-text">
                    <input
                      type="number"
                      className="form-control"
                      id="stake-unstake-df"
                      placeholder="DFB Amount"
                      aria-label="DFB Amount"
                      aria-describedby="inputGroup-sizing-lg"
                      onChange={(e) => setStakeAmount(e.target.value)}
                      value={stakeAmount}
                    />
                    <span className="input-group-text" id="inputGroup-sizing-lg">
                      DFB
                    </span>
                  </div>

                  {(stakeState === "STAKE" && stakeAmount > userTokenBalance) &&
                    <div className="mb-3 text-center">
                      <small className="text-danger">Insufficient DFB Balance !!!</small>
                    </div>
                  }


                  <div className="m-4" />

                  <div className="col-md-12 mb-3 mt-4">

                    {(!active) && (
                      <button
                        className="btn connect mb-3 w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#connectModal"
                      >
                        Connect wallet
                      </button>
                    )}

                    {(active && account && stakeState === "STAKE") && (
                      <>
                        <div className="row">

                          {(stakeState === "STAKE" && stakeAmount > 0 && dfAllowance < stakeAmount) &&
                            <div className="col">
                              <button
                                className="btn btn-light w-100"
                                id="approveDf"
                                onClick={onApproveTokenPressed}
                                disabled={spinner}
                              >
                                {spinner ? "Waiting..." : "Approve DFB"}
                              </button>
                            </div>
                          }

                          {(stakeState === "STAKE" && active && account) && (
                            <div className="col">
                              <button
                                className="btn start w-100"
                                id="stake"
                                onClick={onStakeTokenPressed}
                                disabled={spinner}
                              >
                                {spinner ? "Waiting..." : "Stake DFB"}
                              </button>
                            </div>
                          )}

                        </div>
                      </>
                    )}

                  </div>

                  {(stakeState === "UNSTAKE" && active && account) && (
                    <div>
                      <button
                        className="btn start w-100"
                        id="unstake"
                        onClick={onUnstakeTokenPressed}
                        disabled={spinner}
                      >
                        {spinner ? "Waiting..." : "Unstake DFB"}
                      </button>
                    </div>
                  )}

                  <div className="m-4" />

                </div>







                <div className="project-card detail-card">
                  <h4 className="mb-5">Commit ETH</h4>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col">
                        <div className="price">
                          <span>
                            {amountInvested} <small>ETH</small>
                          </span>
                        </div>
                        <p className="detail-head">You Committed</p>
                      </div>
                      <div className="col">
                        <div className="price">
                          <span>
                            {tokenAmountUserWillGet} <small>{idoData.ticker}</small>
                          </span>
                        </div>
                        <p className="detail-head">You will receive</p>
                      </div>
                    </div>
                  </div>

                  <div className="m-4" />

                  {/* <label htmlFor="commit-eth" className="form-label">
                    Commit ETH
                  </label> */}
                  <div className="input-group input-group-lg mb-3 fc-left-text">
                    <input
                      type="number"
                      className="form-control"
                      id="commit-eth"
                      placeholder="Amount"
                      aria-label="Amount"
                      aria-describedby="inputGroup-sizing-lg"
                      onChange={(e) => setInvestAmount(e.target.value)}
                      value={investAmount}
                    />
                    <span className="input-group-text" id="inputGroup-sizing-lg">
                      ETH
                    </span>
                  </div>

                  {investAmount > userETHBalance &&
                    <div className="mb-3 text-center">
                      <small className="text-danger">Insufficient ETH Balance !!!</small>
                    </div>
                  }

                  <div className="m-4" />

                  <div className="col">

                    {(!active) && (
                      <button
                        className="btn connect mb-3 w-100"
                        data-bs-toggle="modal"
                        data-bs-target="#connectModal"
                      >
                        Connect wallet
                      </button>
                    )}


                    {(active && account) && (
                      <>
                        {(referrer.length > 0) ?
                          <button
                            className="btn start w-100"
                            id="commitETHWithReferral"
                            onClick={onBuyTokenRefPressed}
                            disabled={spinner}
                          >
                            {spinner ? "Waiting..." : "Commit ETH"}
                          </button>
                          :
                          <button
                            className="btn start w-100"
                            id="commitETH"
                            onClick={onBuyTokenPressed}
                            disabled={spinner}
                          >
                            {spinner ? "Waiting..." : "Commit ETH"}
                          </button>
                        }
                      </>
                    )}

                  </div>

                  <div className="m-5" />




                  <h4 className="mb-5">Referral Section</h4>

                  <div className="col-md-12">

                    <div className="row">

                      <div className="col">
                        <div className="price">
                          <span>
                            {userReferralReward} <small>ETH</small>
                          </span>
                        </div>
                        <p className="detail-head">Your Reward</p>
                      </div>

                      <div className="col">
                        <div className="price">
                          <span>
                            {userReferralCount} <small>Users</small>
                          </span>
                        </div>
                        <p className="detail-head">You Referred</p>
                      </div>

                    </div>

                    <div className="row">

                      <div className="col">
                        <div className="price">
                          <span>
                            {totalReferralReward} <small>ETH</small>
                          </span>
                        </div>
                        <p className="detail-head">Current Total Reward</p>
                      </div>

                      <div className="col">
                        <div className="price">
                          <span>
                            {referralPercentage} <small>%</small>
                          </span>
                        </div>
                        <p className="detail-head">Referral Percentage</p>
                      </div>

                    </div>

                    <div className="col">

                      {(userRefLink && amountInvested > 0) ?
                        <>
                          <div className="price">
                            <a
                              href={userRefLink}
                              style={{ overflowWrap: "break-word", color: "aqua" }}
                            >
                              <small>
                                {userRefLink}
                              </small>
                            </a>
                          </div>
                        </>
                        :
                        <>
                          <div className="price">
                            <small>
                              You must commit ETH to get referral link !!! You will get {referralPercentage}% of the amount
                              committed by every investor you refer !
                            </small>
                          </div>
                        </>
                      }

                      <p className="detail-head">Your Referral Link</p>

                    </div>

                  </div>

                  <div className="m-4" />

                </div>






                <div className="project-card detail-card">

                  <h4 className="mb-5">Actions</h4>

                  <p style={{ fontSize: 15, color: "bisque" }}>
                    {status}
                  </p>

                  <div className="m-4" />

                  {(spinner) &&
                    <>
                      <div className="col-md-12 mb-3">
                        <div>
                          <button className="btn btn-light w-100">
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />
                            &nbsp; Pending Txs...
                          </button>
                        </div>
                      </div>

                      <div className="m-4" />
                    </>
                  }

                  {(claimEnabled && !spinner) &&
                    <>
                      <div className="col">
                        <button
                          className="btn start w-100"
                          id="claim"
                          onClick={onClaimPressed}
                          disabled={spinner}
                        >
                          Claim {idoData.ticker} Token Vesting {activeVesting}
                        </button>
                      </div>
                      <div className="m-4" />
                    </>
                  }

                  {(claimEnabled && !refRewardClaimed && userReferralReward > 0 && !spinner) &&
                    <>
                      <div className="col">
                        <button
                          className="btn start w-100"
                          id="calimReferral"
                          onClick={onClaimRefRewardPressed}
                          disabled={spinner}
                        >
                          Claim Your Referral Reward ({userReferralReward} ETH)
                        </button>
                      </div>

                      <div className="m-4" />
                    </>
                  }

                  {(saleCompleted && !claimEnabled && !refundEnabled && !securityRefundEnabled && !spinner) &&
                    <>
                      <div className="col">
                        <button
                          className="btn start w-100"
                          id="finaliseSale"
                          onClick={onFinaliseSalePressed}
                          disabled={spinner}
                        >
                          Finalise Sale
                        </button>
                      </div>

                      <div className="m-4" />
                    </>
                  }

                  {(saleCompleted && claimEnabled && !teamFundsWithdrawn && !spinner) &&
                    <>
                      <div className="col">
                        <button
                          className="btn start w-100"
                          id="withdrawTeamFunds"
                          onClick={onWithdrawTeamFundsPressed}
                          disabled={spinner}
                        >
                          Withdraw Team Funds
                        </button>
                      </div>

                      <div className="m-4" />
                    </>
                  }

                  {(saleCompleted && claimEnabled && teamFundsWithdrawn && !platformFundsWithdrawn && !spinner) &&
                    <>
                      <div className="col">
                        <button
                          className="btn start w-100"
                          id="withdrawPlatformFunds"
                          onClick={onWithdrawPlatformFundsPressed}
                          disabled={spinner}
                        >
                          Withdraw Platform Funds
                        </button>
                      </div>

                      <div className="m-4" />
                    </>
                  }

                  {(refundEnabled && !spinner) &&
                    <>
                      <div className="col">
                        <button
                          className="btn start w-100"
                          id="refund"
                          onClick={onRefundPressed}
                          disabled={spinner}
                        >
                          Get Your ETH Refund
                        </button>
                      </div>

                      <div className="m-4" />
                    </>
                  }

                  {(votingPossible && !spinner) &&
                    <>
                      <div className="col">
                        <button
                          className="btn start w-100"
                          id="vote"
                          onClick={onVotePressed}
                          disabled={spinner}
                        >
                          {userVoted ? "Voting in Progress. You already voted" : "Vote For Security ETH Refund"}
                        </button>
                      </div>

                      <div className="m-4" />
                    </>
                  }


                  {(saleCompleted && securityRefundEnabled && !spinner) &&
                    <>
                      <div className="col">
                        <button
                          className="btn start w-100"
                          id="securityRefund"
                          onClick={onSecurityRefundPressed}
                          disabled={spinner}
                        >
                          Get Your Security ETH Refund
                        </button>
                      </div>

                      <div className="m-4" />
                    </>
                  }

                </div>

              </div>
            </div>
          </div>
        </div>





        <section id="other-details" className="project-other-details">
          <div className="container">
            <div className="py-4">
              <h1 className="project-title">{idoData.name}</h1>
            </div>
            <div>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-metric-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#metrics"
                    type="button"
                    role="tab"
                    aria-controls="metrics"
                    aria-selected="true"
                  >
                    Fairlaunch
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-story-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#story"
                    type="button"
                    role="tab"
                    aria-controls="story"
                    aria-selected="false"
                  >
                    Details
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-instructions-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#instructions"
                    type="button"
                    role="tab"
                    aria-controls="instructions"
                    aria-selected="false"
                  >
                    Instructions
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="metrics"
                  role="tabpanel"
                  aria-labelledby="pills-metric-tab"
                >
                  <div className="content-block">
                    <h2>Fairlaunch and Token Details</h2>
                  </div>
                  <table className="table">
                    <tbody>
                      <tr>
                        <th scope="row">Token Name: </th>
                        <td>{idoData.name}</td>
                      </tr>
                      <tr>
                        <th scope="row">Symbol/NFT: </th>
                        <td>{idoData.ticker}</td>
                      </tr>
                      <tr>
                        <th scope="row">Links: </th>
                        <td>
                          <a href="">{idoData.website}</a>
                          &emsp;
                          <a href="">{idoData.twitter}</a>
                          &emsp;
                          <a href="">{idoData.telegram}</a>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Blockchain Network: </th>
                        <td>Base Network</td>
                      </tr>
                      <tr>
                        <th scope="row">Pool Supply: </th>
                        <td>{totalPoolSupply} {idoData.ticker}</td>
                      </tr>
                      <tr>
                        <th scope="row">Liquidity Supply: </th>
                        <td>{totalLiquidityTokenAmount} {idoData.ticker}</td>
                      </tr>
                      <tr>
                        <th scope="row">Initial Total Supply: </th>
                        <td>{totalPoolSupply + totalLiquidityTokenAmount} {idoData.ticker}</td>
                      </tr>
                      <tr>
                        <th scope="row">Raise ETH for Liquidity: </th>
                        <td>{liquidityEthPercentage} %</td>
                      </tr>
                      <tr>
                        <th scope="row">Raise ETH for Team: </th>
                        <td>{teamPercentage} %</td>
                      </tr>
                      <tr>
                        <th scope="row">Raise ETH for Referral: </th>
                        <td>{referralPercentage} %</td>
                      </tr>
                      <tr>
                        <th scope="row">Fairlaunch Contract: </th>
                        <td>{idoContractAddr}</td>
                      </tr>
                      <tr>
                        <th scope="row">Token Contract: </th>
                        <td>{idoData.token_contract}</td>
                      </tr>
                      <tr>
                        <th scope="row">Token LP Staking Contract: </th>
                        <td>{idoData.lp_staking_contract}</td>
                      </tr>
                      <tr>
                        <th scope="row">Token LP Staking Link: </th>
                        <td>
                          <a
                            href={idoData.lp_staking_link}
                            style={{ overflowWrap: "break-word", color: "aqua" }}
                          >
                            {idoData.lp_staking_link}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  className="tab-pane fade"
                  id="story"
                  role="tabpanel"
                  aria-labelledby="pills-story-tab"
                >
                  <div className="content-block">
                    <h2>Project Details</h2>
                    <p>
                      {idoData.s_detail}
                    </p>
                    <p>
                      {idoData.f_detail}
                    </p>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="instructions"
                  role="tabpanel"
                  aria-labelledby="pills-instructions-tab"
                >
                  <div className="content-block">
                    <h2>Instructions</h2>
                    <p className="detail-head">How to invest:</p>
                    <p>
                      Go to the Commit ETH tab and input ETH amount the click Commit
                      ETH button
                    </p>
                    <p className="detail-head">How to stake:</p>
                    <p>
                      Go to the Stake/Unstake DFB tab, select Stake input DFB amount
                      then click Approve DFB button if it shows up, then click Stake
                      DFB button
                    </p>
                    <p className="detail-head">How to unstake:</p>
                    <p>
                      Go to the Stake/Unstake DFB tab, select Unstake input DFB
                      amount then click Unstake DFB button
                    </p>
                    <p className="detail-head">Amount to stake:</p>
                    <p>
                      You must stake a minimum of {minDFStake} DFB to be eligible to
                      invest in all fairlaunches on the platform. Note that DFB must
                      be staked on all blockchain, so if you stake DFB on Base, you
                      can only invest in Base Blockchain fairlaunch projects on the
                      platform, to invest in Ethereum Blockchain fairlaunch
                      projects, you must stake DF on Ethereum too.
                    </p>
                    <p className="detail-head">Minimum days to stake:</p>
                    <p>
                      You must have staked {minDFStake} DFB for minimum of {minimumStakeDays} days before you
                      can invest in the Fairlaunch
                    </p>
                    <p className="detail-head">Minimum days to Unstake:</p>
                    <p>You can only unstake after {minDaysToUnstake} days.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>










      <div className="modal" id="connectModal" tabIndex={-1}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Connect wallet</h5>
              <button
                type="button"
                className="btn-close"
                style={{ backgroundColor: "white" }}
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={modalCloseButtonRef}
              />
            </div>
            <div className="modal-body">
              <div className="wallet-inner text-center">
                <p>Choose a provider to connect wallet </p>
                <div className="p-3" />
                <div className="wallet-btns row">
                  <div className="metamask col">
                    <p
                      className="metamask-btn"
                      id="MetaMaskButton"
                      style={{ cursor: "pointer" }}
                      onClick={metamaskConnectPressed}
                    >
                      <img src="images/wallets/metamask-lg.png" alt="" />
                      MetaMask
                    </p>
                  </div>
                  <div className="metamask col">
                    <p
                      className="metamask-btn"
                      id="WalletConnect"
                      style={{ cursor: "pointer" }}
                      onClick={walletconnectConnectPressed}
                    >
                      <img src="images/wallets/walletconnect-lg.png" alt="" />
                      WalletConnect
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </>


  );
};

export default AppEngine;
