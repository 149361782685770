//load UserETHBalance
export const loadUserETHBalance = async (library, userAddress) => {
    const result = await library.eth.getBalance(userAddress);
    return library.utils.fromWei(result, 'ether');
};

//load UserTokenBalance
export const loadUserTokenBalance = async (library, tokenContract, userAddress) => {
    const result = await tokenContract.methods.balanceOf(userAddress).call();
    return library.utils.fromWei(result, 'ether');
};

//load TokenAllowance
export const loadTokenAllowance = async (library, tokenContract, userAddress, SpenderAddr) => {
    const balance = await tokenContract.methods.allowance(userAddress, SpenderAddr).call();
    return library.utils.fromWei(balance, 'ether')
};
///////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////
// ROUTER GET

//load TotalAmountRaisedOnThePlatform
export const loadTotalAmountRaisedOnThePlatform = async (library, routerContract) => {
    const result = await routerContract.methods.getTotalAmountRaisedOnThePlatform().call();
    return library.utils.fromWei(result, 'ether')
};

//load UserReferralReward
export const loadUserReferralReward = async (library, routerContract, idoContractAddr, userAddress) => {
    const result = await routerContract.methods.getUserReferralReward(idoContractAddr, userAddress).call();
    return library.utils.fromWei(result, 'ether')
};

//load RefRewardClaimed
export const loadRefRewardClaimed = async (routerContract, idoContractAddr, userAddress) => {
    const result = await routerContract.methods.getRefRewardClaimed(idoContractAddr, userAddress).call();
    return result;
};

//load UserReferralCount
export const loadUserReferralCount = async (routerContract, idoContractAddr, userAddress) => {
    const result = await routerContract.methods.getUserReferralCount(idoContractAddr, userAddress).call();
    return result;
};

//load TotalReferralReward
export const loadTotalReferralReward = async (library, routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTotalReferralReward(idoContractAddr).call();
    return library.utils.fromWei(result, 'ether')
};

//load TotalReferralRewardClaimed
export const loadTotalReferralRewardClaimed = async (library, routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTotalReferralRewardClaimed(idoContractAddr).call();
    return library.utils.fromWei(result, 'ether')
};

//load ReferralPercentage
export const loadReferralPercentage = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getReferralPercentage(idoContractAddr).call();
    return result;
}; 

//load TotalVotes
export const loadTotalVotes = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTotalVotes(idoContractAddr).call();
    return result;
}; 

//load VotePercentageRequired
export const loadVotePercentageRequired = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getVotePercentageRequired(idoContractAddr).call();
    return result;
}; 

//load VotingPossible
export const loadVotingPossible = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getVotingPossible(idoContractAddr).call();
    return result;
};

//load UserVoted
export const loadUserVoted = async (routerContract, idoContractAddr, userAddress) => {
    const result = await routerContract.methods.getUserVoted(idoContractAddr, userAddress).call();
    return result;
};

//load SecurityRefundEnabled
export const loadSecurityRefundEnabled = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getSecurityRefundEnabled(idoContractAddr).call();
    return result;
};

//load AmountInvested
export const loadAmountInvested = async (library, routerContract, idoContractAddr, userAddress) => {
    const result = await routerContract.methods.getAmountInvested(idoContractAddr, userAddress).call();
    return library.utils.fromWei(result, 'ether')
};

//load TotalPoolSupply
export const loadTotalPoolSupply = async (library, routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTotalPoolSupply(idoContractAddr).call();
    return library.utils.fromWei(result, 'ether')
};

//load LiquidityTokenPercentage
export const loadLiquidityTokenPercentage = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getLiquidityTokenPercentage(idoContractAddr).call();
    return result;
};

//load TotalLiquidityTokenAmount
export const loadTotalLiquidityTokenAmount = async (library, routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTotalLiquidityTokenAmount(idoContractAddr).call();
    return library.utils.fromWei(result, 'ether')
};

//load LiquidityEthPercentage
export const loadLiquidityEthPercentage = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getLiquidityEthPercentage(idoContractAddr).call();
    return result;
};

//load TeamPercentage
export const loadTeamPercentage = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTeamPercentage(idoContractAddr).call();
    return result;
};

//load PlatformPercentage
export const loadPlatformPercentage = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getPlatformPercentage(idoContractAddr).call();
    return result;
};

//load TotalInvested
export const loadTotalInvested = async (library, routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTotalInvested(idoContractAddr).call();
    return library.utils.fromWei(result, 'ether')
};

//load TotalInvestors
export const loadTotalInvestors = async (library, routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTotalInvestors(idoContractAddr).call();
    return result;
};

//load TotalTokenClaimed
export const loadTotalTokenClaimed = async (library, routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTotalTokenClaimed(idoContractAddr).call();
    return library.utils.fromWei(result, 'ether')
};

//load TotalRefundClaimed
export const loadTotalRefundClaimed = async (library, routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTotalRefundClaimed(idoContractAddr).call();
    return library.utils.fromWei(result, 'ether')
};

//load AmountClaimed
export const loadAmountClaimed = async (library, routerContract, idoContractAddr, userAddress) => {
    const result = await routerContract.methods.getAmountClaimed(idoContractAddr, userAddress).call();
    return library.utils.fromWei(result, 'ether')
};

//load VestingPercentage
export const loadVestingPercentage = async (library, routerContract, idoContractAddr, vestingNumber) => {
    const result = await routerContract.methods.getVestingPercentage(idoContractAddr, vestingNumber).call();
    return result;
};

//load SaleEnabled
export const loadSaleEnabled = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getSaleEnabled(idoContractAddr).call();
    return result;
};

//load SaleCompleted
export const loadSaleCompleted = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getSaleCompleted(idoContractAddr).call();
    return result;
};

//load StartDate
export const loadStartDate = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getStartDate(idoContractAddr).call();
    return result;
};

//load EndDate
export const loadEndDate = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getEndDate(idoContractAddr).call();
    return result;
};

//load ClaimEnabled
export const loadClaimEnabled = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getClaimEnabled(idoContractAddr).call();
    return result;
};

//load RefundEnabled
export const loadRefundEnabled = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getRefundEnabled(idoContractAddr).call();
    return result;
};

//load ActiveVesting
export const loadActiveVesting = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getActiveVesting(idoContractAddr).call();
    return result;
};

//load TokenAmountUserWillGet
export const loadTokenAmountUserWillGet = async (library, routerContract, idoContractAddr, userAddress) => {
    const result = await routerContract.methods.getTokenAmountUserWillGet(idoContractAddr, userAddress).call();
    return library.utils.fromWei(result, 'ether')
};

//load MaximumBuy
export const loadMaximumBuy = async (library, routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getMaximumBuy(idoContractAddr).call();
    return library.utils.fromWei(result, 'ether')
};

//load MinimumBuy
export const loadMinimumBuy = async (library, routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getMinimumBuy(idoContractAddr).call();
    return library.utils.fromWei(result, 'ether')
};

//load TeamAddress
export const loadTeamAddress = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTeamAddress(idoContractAddr).call();
    return result;
};

//load PlatformAddress
export const loadPlatformAddress = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getPlatformAddress(idoContractAddr).call();
    return result;
};

//load TotalVesting
export const loadTotalVesting = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTotalVesting(idoContractAddr).call();
    return result;
};

//load TeamFundsWithdrawn
export const loadTeamFundsWithdrawn = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getTeamFundsWithdrawn(idoContractAddr).call();
    return result;
};

//load PlatformFundsWithdrawn
export const loadPlatformFundsWithdrawn = async (routerContract, idoContractAddr) => {
    const result = await routerContract.methods.getPlatformFundsWithdrawn(idoContractAddr).call();
    return result;
};








// STAKING GET
export const loadUserDFAmountStaked = async (library, stakingContract, userAddress) => {
    const result = await stakingContract.methods.getUserDFAmountStaked(userAddress).call();
    return library.utils.fromWei(result, 'ether');
};
export const loadUserDF_StakeDaysCount = async (stakingContract, userAddress) => {
    const result = await stakingContract.methods.DF_StakeDaysCount(userAddress).call();
    return result;
};
export const loadMinimumStakeDays = async (stakingContract) => {
    const result = await stakingContract.methods.getMinimumStakeDays().call();
    return result;
};
export const loadMinDaysToUnstake = async (stakingContract) => {
    const result = await stakingContract.methods.getMinimumDaysToUnStake().call();
    return result;
};
export const loadMinDFStake = async (library, stakingContract) => {
    const result = await stakingContract.methods.getMinimum_DF_Stake().call();
    return library.utils.fromWei(result, 'ether');
};
export const loadTotalDFAmountStaked = async (library, stakingContract) => {
    const result = await stakingContract.methods.getTotalDFAmountStaked().call();
    return library.utils.fromWei(result, 'ether');
};
export const loadNoUnstakeDays = async (stakingContract) => {
    const result = await stakingContract.methods.getNoUnStakeDays().call();
    return result;
};
export const loadUnstakeFee1 = async (stakingContract) => {
    const result = await stakingContract.methods.getUnripeUnStakeFee1().call();
    return result;
};
export const loadUnstakeFee2 = async (stakingContract) => {
    const result = await stakingContract.methods.getUnripeUnStakeFee2().call();
    return result;
};
///////////////////////////////////////////////////////////////






// ROUTER SET


//Approve tokens
export const approveTokenSpending = async (
    library, tokenContract, tokenContractAddress, spenderAddr, address, amount
) => {

    if (amount.toString().trim() === "" || amount <= 0) {
        return {
            status: "Please Input Amount.",
        };
    }

    // Error: Please pass numbers as strings or BN objects to avoid precision errors.
    // Cause is, the toWei wants a string, so this is the solution
    // Or this String(amount)
    // amount = amount.toString();

    //set up transaction parameters
    const transactionParameters = {
        to: tokenContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: tokenContract.methods.approve(spenderAddr, library.utils.toWei(String(amount), 'ether')).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Approve Successful."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};



//Normal buy
export const buy = async (library, routerContractAddress, routerContract, idoContractAddr, address, investAmount) => {

    if (investAmount.toString().trim() === "" || investAmount <= 0) {
        return {
            status: "Please Input ETH Amount.",
        };
    }

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        // value: library.utils.toHex(library.utils.toWei(investAmount, 'ether')),
        data: routerContract.methods.buy(idoContractAddr).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Your investment was sent."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Claim Token
export const claim = async (library, routerContractAddress, routerContract, idoContractAddr, address) => {

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: routerContract.methods.claim(idoContractAddr).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Claim Successful."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Refund
export const refund = async (library, routerContractAddress, routerContract, idoContractAddr, address) => {

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: routerContract.methods.refund(idoContractAddr).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Refund Successful."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Referral buy
export const buyWithReferral = async (library, routerContractAddress, routerContract, idoContractAddr, address, referrer, investAmount) => {

    if (investAmount.toString().trim() === "" || investAmount <= 0) {
        return {
            status: "Please Input ETH Amount.",
        };
    }

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        // value: library.utils.toHex(library.utils.toWei(investAmount, 'ether')),
        data: routerContract.methods.buyWithReferral(idoContractAddr, referrer).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Your investment was sent."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Claim Referral Reward
export const claimReferralReward = async (library, routerContractAddress, routerContract, idoContractAddr, address) => {

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: routerContract.methods.claimReferralReward(idoContractAddr).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Referral reward claimed successfully."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Finalise sale
export const finaliseSale = async (library, routerContractAddress, routerContract, idoContractAddr, address) => {

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: routerContract.methods.finaliseSale(idoContractAddr).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Sale finalised successfully."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Vote for Security Refund
export const securityRefundVote = async (library, routerContractAddress, routerContract, idoContractAddr, address) => {

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: routerContract.methods.securityRefundVote(idoContractAddr).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ You voted for security refund successfully."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Get refund through Security Refund
export const securityRefund = async (library, routerContractAddress, routerContract, idoContractAddr, address) => {

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: routerContract.methods.securityRefund(idoContractAddr).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Refund using Security Refund Successful."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Withdraw team funds
export const withdrawTeamFunds = async (library, routerContractAddress, routerContract, idoContractAddr, address) => {

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: routerContract.methods.withdrawTeamFunds(idoContractAddr).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Team Funds Withdrawn Successfully."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

//Withdraw platform funds
export const withdrawPlatformFunds = async (library, routerContractAddress, routerContract, idoContractAddr, address) => {

    //set up transaction parameters
    const transactionParameters = {
        to: routerContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        data: routerContract.methods.withdrawPlatformFunds(idoContractAddr).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ Platform Funds Withdrawn Successfully."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};
























// STAKING SET
export const stakeDFToken = async (library, stakingContractAddress, stakingContract, address, stakeAmount) => {

    if (stakeAmount.toString().trim() === "" || stakeAmount <= 0) {
        return {
            status: "Please Input Stake Amount.",
        };
    }

    // Error: Please pass numbers as strings or BN objects to avoid precision errors.
    // Cause is, the toWei wants a string, so this is the solution
    // Or this String(investAmount)
    // stakeAmount = stakeAmount.toString();

    //set up transaction parameters
    const transactionParameters = {
        to: stakingContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        // value: library.utils.toHex(library.utils.toWei(stakeAmount, 'ether')),
        data: stakingContract.methods.stakeDF(library.utils.toWei(String(stakeAmount), 'ether')).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ You have successfully staked DFB tokens."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {
        return {
            status: error.message,
        };
    }
};

export const unstakeDFToken = async (library, stakingContractAddress, stakingContract, address, unstakeAmount) => {

    if (unstakeAmount.toString().trim() === "" || unstakeAmount <= 0) {
        return {
            status: "Please Input Unstake Amount.",
        };
    }

    // Error: Please pass numbers as strings or BN objects to avoid precision errors.
    // Cause is, the toWei wants a string, so this is the solution
    // Or this String(investAmount)
    // unstakeAmount = unstakeAmount.toString();
    
    //set up transaction parameters
    const transactionParameters = {
        to: stakingContractAddress, // Required except during contract publications.
        from: address, // must match user's active address.
        // value: library.utils.toHex(library.utils.toWei(unstakeAmount, 'ether')),
        data: stakingContract.methods.unstakeDF(library.utils.toWei(String(unstakeAmount), 'ether')).encodeABI(),
    };

    // sign the transaction
    try {
        const txHash = await library.eth.sendTransaction(transactionParameters);
        return {
            status: (
                "✅ You have successfully unstaked DFB tokens."
            ),
            smUpdate: (`Smart Contract Updated ${txHash.transactionHash}`)
        };
    } catch (error) {console.log(error);
        return {
            status: error.message,
        };
    }
};
