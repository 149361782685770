import AppEngine from './AppEngine'
import { Web3ReactProvider } from "@web3-react/core";

var Web3 = require("web3");

function getLibrary(provider) {
  const library = new Web3(provider);
  library.pollingInterval = 8000;
  return library;
}

// To get IDO address from URL
function getIdoContractAddress() {
  if (window.location.href.indexOf("/0x") > 0) {
    var completeUrl = window.location.href;
    var idoAddrStr = completeUrl.substring(completeUrl.indexOf("/0x") + 1);
    idoAddrStr = idoAddrStr.substring(0, 42);
    return idoAddrStr;
  } else {
    return "";
  }
}


function App() {
  return (
    <Web3ReactProvider
      getLibrary={getLibrary}
    >
      <AppEngine idoContractAddr={getIdoContractAddress()} ></AppEngine>
    </Web3ReactProvider>
  );
}

export default App;
